import React from "react";
import "./Home.css";
import kebab from '../../assets/images/slider-4.jpg';
import pizza from '../../assets/images/slider-1.jpg';
import burger from '../../assets/images/slider-3.jpg';
const Home = () => {
    return (
        <div className="fluid-container">
            <div className="row">
                <div className="image-text-container col-sm-12 col-md-4 nopadding">
                    <img className="img-fluid" src={kebab}></img>
                    <p className="text-in-image-centered">würzig, frisch und einfach unwiderstehlich!</p>
                </div>
                <div className="col-sm-12 col-md-4 nopadding">
                    <img src={pizza} className="img-fluid" ></img>
                    <p className="text-in-image-centered">knusprig, reich belegt und herrlich herzhaft</p>
                </div>
                <div className="vc_column_container col-sm-12 col-md-4 nopadding">
                    <img className="img-fluid" src={burger}></img>
                    <p className="text-in-image-centered">saftig, geschichtet und vollkommen</p>
                </div>
            </div>
            <div className="container text-center">

                {/* Welcome Text */}
                <p className="welcome-text">
                    Willkommen bei Sofra! 🍕 <br />
                    Hier erwartet Sie die beste Auswahl an Kebap und Pizza – frisch zubereitet und mit Liebe serviert.
                    Egal ob Sie bei uns essen, mitnehmen oder liefern lassen möchten – wir sorgen dafür, dass Sie
                    immer ein köstliches Erlebnis genießen!
                </p>

                {/* Restaurant Name */}
                <h1 className="home-title">Sofra</h1>
                <h2 className="home-subtitle">Kebap & Pizza</h2>

                {/* Contact Information */}
                <div className="contact-info">
                    <p>Telefon: 0316 317584 - 0681 84414129</p>
                    <p>Zustellung gratis</p>
                </div>

                {/* Special Offer */}
                <div className="offer">
                    <p><strong>Alle Speisen auch zum Mitnehmen</strong></p>
                    <p>Jede 10. Pizza GRATIS! (mit Pizza-Pass zur Abholung)</p>
                    <p>Mindestbestellwert ab € 14,90,-</p>
                </div>

                {/* Location and Hours */}
                <div className="location">
                    <p>Seiersberg AT | Kärntner Str. 520 | 8054 Graz</p>
                    <p>Öffnungszeiten: täglich von 10:00 - 22:00 Uhr geöffnet</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
