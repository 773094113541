import React from "react";
import './Impressum.css';

// export default function App() {
// const App = () => {

const Impressum = () => {
    return (
        <div className="home-container">
            <div className="home-content">
                {/* Restaurant Name */}
                <h1 className="home-title">Sofra</h1>
                <h2 className="home-subtitle">Kebap & Pizza</h2>
                {/* Welcome Text */}
                <p className="welcome-text">
                    Wir sind bemüht, aktuelle, richtige und vollständige Informationen auf dieser Website bereitzustellen, übernehmen aber keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten Informationen, einschließlich jeglicher Datenbankeinträge, richtig, vollständig oder aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichten uns auch nicht, die enthaltenen Informationen laufend zu aktualisieren. Aus den Angaben unserer Website leiten sich auch keine Rechtsansprüche ab.
                </p>
                {/* Special Offer */}
                <div className="offer">
                    <p><strong>Verantwortlich für den Inhalt:</strong></p>
                    <p>Sofra Grill</p>
                    <p>Seiersberg AT | Kärntner Str. 520 | 8054 Graz</p>
                    <p>Öffnungszeiten: täglich von 10:00 - 22:00 Uhr geöffnet</p>
                    <p>Telefon: 0316 317584 - 0681 84414129</p>
                </div>
            </div>
        </div>
    );
};
export default Impressum;