import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './NavBar.css';

const NavBar = () => {
    const [sideNavWidth, setSideNavWidth] = useState(0);
    const toggleMenu = () => {
        //setIsMenuOpen(!isMenuOpen);
        setSideNavWidth(250);
    };
    const closeNav = () => {
        setSideNavWidth(0);
    };
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="navbar">
                <div className="container-fluid">

                    <NavLink to="/" className="navbar-brand-link">
                        <i className="fa fa-home"></i>
                    </NavLink>

                    <div className="header-nav-phone-top">Tel: 0316 317584<br />Mob: 0681 84414129</div>


                    <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="horizontal-menu">
                            <li><NavLink to="/" exact activeClassName="active-link">Home</NavLink></li>
                            <li><NavLink to="/menu" activeClassName="active">Karte</NavLink></li>
                            <li><NavLink to="/impressum" activeClassName="active">Impressum</NavLink></li>
                            <li><NavLink to="/contact" activeClassName="active">Kontakt</NavLink></li>
                        </ul>

                    </div>

                </div>

            </nav>

            <div className="sidenav" style={{ width: `${sideNavWidth}px` }}>
                <a className="navbar-brand" href="javascript:void(0)" onClick={closeNav}><i className="fa fa-window-close" aria-hidden="true"></i></a>

                <ul>
                    <li><NavLink to="/" onClick={closeNav}>Home</NavLink></li>
                    <li><NavLink to="/menu" onClick={closeNav}>Speisekarte</NavLink></li>
                    <li><NavLink to="/impressum" onClick={closeNav}>Impressum</NavLink></li>
                    <li><NavLink to="/contact" onClick={closeNav}>Kontakt</NavLink></li>
                </ul>
            </div>

        </div>
    );
}
export default NavBar;
