import React from 'react';
import './GoogleMapEmbed.css'

const GoogleMapEmbed = () => {
    return (
        <div className='container'>
            {/* Company Information */}
            <div className='title'>
                <h1>Kontakt</h1>

            </div>
            {/* Company Information */}
            <div className='info'>
                <h1 className="home-title">Sofra</h1>
                <h2 className="home-subtitle">Kebap & Pizza</h2>
                <p>Telefon: 0316 317584 - 0681 84414129<br />
                    Seiersberg AT | Kärntner Str. 520 | 8054 Graz<br />
                    Öffnungszeiten: täglich von 10:00 - 22:00 Uhr geöffnet</p>
            </div>

            {/* Google Map Embed */}

            <div className='title'>
                <h3>Adresse und Anfahrt</h3>

            </div>
            <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9150.252630087914!2d15.391948848408193!3d47.01302026770674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476fcb6897f26097%3A0x419fc534c0f5ff7!2sSofra-Grill!5e0!3m2!1sde!2sat!4v1730493216200!5m2!1sde!2sat"
                    className="map"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    );
}
export default GoogleMapEmbed;