import React from 'react';
import menuData from '../../assets/menu.json'; // Adjust the path as needed
import './Menu.css';

const Menu = () => {
    console.log(menuData); // Log the menu data to verify its structure

    // Convert the menuData object into a format for rendering
    const sections = Object.keys(menuData).map((category) => ({
        title: category,
        data: Array.isArray(menuData[category]) ? menuData[category] : [], // Ensure data is an array
    }));

    return (
        <div className='page-content'>
            {sections.map((section) => (
                <div key={section.title} style={{ marginBottom: '20px' }}>
                    <h2 className='categoryTitle' >{section.title}</h2>
                    {section.data.map((item) => (
                        <div className="menu-item">
                            <div className="menu-description">
                                <div className="dish-title">{item.name}</div>
                                <div className="dish-options">{item.description}<sub className='allergens'>
                                    {(item.allergens) ? ('') : ''}
                                    <span >
                                        {Array.isArray(item.allergens) ? item.allergens.join(', ') : ''}
                                    </span>
                                </sub></div>
                            </div>

                            {
                                (item.price) ?
                                    (


                                        <div className='menu-price'>Preis: {item.price ? Number(item.price).toFixed(2).replace('.', ',') : '0.00'} €</div>)
                                    :
                                    ("")
                            }
                        </div>


                    ))}
                </div>
            ))
            }
        </div >
    );
};



export default Menu;
