import React from "react";
import './Contact.css';
import GoogleMapEmbed from "../GoogleMapEmbed/GoogleMapEmbed";

// export default function App() {
// const App = () => {

const Contact = () => {
    return (

        <GoogleMapEmbed></GoogleMapEmbed>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '1rem',
    },
    info: {
        marginBottom: '1rem',
        textAlign: 'center',
    },
    mapContainer: {
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        width: '100%',
        height: 0,
        overflow: 'hidden',
    },
    map: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 0,
    },
};
export default Contact;