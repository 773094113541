import React from 'react';
import './MobileHeader.css'; // CSS file for styling

const MobileHeader = () => {
    return (
        <div className='mobile-header-image'>
            <div className="mobile-header">
                {/* Left: Logo */}
                <div className="header-logo">SOFRA</div>

                {/* Center: Phone Number */}
                <div className="header-phone">0316/31758</div>

                {/* Right: Hamburger Menu */}
                <button className="burger-menu">
                    <i className="fa fa-bars"></i> {/* Uses FontAwesome for the icon */}
                </button>
            </div>
        </div>

    );
};

export default MobileHeader;