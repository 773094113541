import React from "react";
import logo from '../../assets/images/logo.png';
import './Header.css';
import MobileHeader from "./MobileHeader";

const Header = () => {
    return (
        <div className="container-header">
            <MobileHeader />
            <div>
                <header className="header-banner">
                    <div className="header-top">
                        <div className="header-logo-container">
                            {/* Restaurant Name */}
                            <h1 className="header-title">Sofra</h1>
                            <h2 className="header-subtitle">Kebap & Pizza</h2>
                        </div>
                        <div className="header-text-container">
                            {/* Restaurant Name */}
                            {/* Contact Information */}
                            <div className="contact-info">
                                <p> täglich geöffnet <br />von 10:00 - 22:00 Uhr</p>
                            </div>
                        </div></div>

                    <div className="header-bottom">
                        <div className="header-order">
                            <p>Bestellung und Reservierung<br /> 0316/317584 und 0681/84414129</p>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
}
export default Header;
