
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import Header from './components/Header/Header';
import Contact from './components/Contact/Contact';
import NavBar from './components/NavBar/NavBar';
import Impressum from './components/Impressum/Impressum';

// export default function App() {
// const App = () => {

function App() {
  return (
    <Router>
      <Header />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} ></Route>
        <Route path="/menu" element={<Menu />} ></Route>
        <Route path="/impressum" element={<Impressum />} ></Route>
        <Route path="/contact" element={<Contact />} ></Route>
      </Routes>
    </Router>
  );
}
export default App;
